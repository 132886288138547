$prefix: "app-";
$theme-colors: (
  primary: #27b9fb,
  danger: #ff0000,
  success: #8bc33c,
);

@import "bootstrap/scss/bootstrap.scss";

html,
body {
  margin: 0;
  background: #eee;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

h3 {
  font-weight: 300;
}

.page-loader {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-site-key {
  text-align: center;
  margin: 40px 0;
  border: 1px dashed #ccc;
  padding: 40px;
  background: #f9f9f9;

  .message {
    margin-bottom: 20px;
    color: #999;
  }
}

.form-control-error {
  color: #ff0000;
  margin-top: 4px;
}

.message-error {
  margin: 40px;
  text-align: center;

  > div {
    color: #ff0000;
    margin-bottom: 24px;
  }
}
